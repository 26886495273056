import { IMetrics, IStats, IEnv, IFeature, IFeatureData, IWinTypeData, IWinDistribution } from "../../interface";
import numeral from 'numeral';

export const formatRtp = (n: number) => numeral(n).format('0,0.000%');
export const formatNum = (n: number) => numeral(n).format('0,0.00');

export const wrapRtp = (stats: IStats) => {
  let tBets = 0;
  let totalGlobalWins = 0;
  let totalGlobalCount = 0;
  const totalWinsOfEnvs: { [env: string]: number } = {};
  Object.keys(stats.envs).map(it => Object.keys(stats.envs[it].totalBetsUsed).forEach(k => tBets += Number(k) * Number(stats.envs[it].totalBetsUsed[k])));
  const envs = Object.entries(stats.envs);
  envs.forEach(([name, env]: [string, IEnv]) => {
    env.metric.rtp = 0.0;
    env.metric.maxWin = 0.0;
    let envTotalWin = 0;
    const count = getEnvCounts(env);
    totalGlobalCount += count;
    Object.entries(env.features).forEach(([featureName, feature]: [string, IFeature]) => {
      feature.metric.rtp = 0.0;
      feature.metric.maxWin = 0.0;
      Object.entries(feature.data).forEach(([dKey, it]: [string, IFeatureData]) => {
        it.rtpContribution = (it.win) / tBets;
        it.envHitRate = count / it.count;
      });
      Object.entries(env.symbolWinTypes).forEach(([swtKey, winType]: [string, IWinTypeData]) => {
        winType.rtpContribution = winType.win /  tBets;
        winType.envHitRate = count / winType.count;
      });
      Object.entries(feature.totalWinsDistribution).forEach(([wdKey, it]: [string, IWinDistribution]) => {
        const win = it.win * it.count;
        env.metric.maxWin < it.win && (env.metric.maxWin = it.win);
        feature.metric.maxWin < it.win && (feature.metric.maxWin = it.win);
        it.rtp = win / tBets;
        envTotalWin += win;
        totalGlobalWins += win;
      });
      totalWinsOfEnvs[name] = envTotalWin;
    });
    env.metric.meanTotalWins = envTotalWin / count;
  });

  stats.metric.meanTotalWins = totalGlobalWins / totalGlobalCount;
  
  // Calculate standard deviation and rtp
  let totalSumOverall = 0.0;
  envs.forEach(([name, env]: [string, IEnv]) => {      
    let envSum = 0.0;
    const count = getEnvCounts(env);
    Object.entries(env.features).forEach(([featureName, feature]: [string, IFeature]) => { // (lines | ways) | (coins.coin|coin.grand)
      let featureTotalWin = 0;
      let featureSum = 0.0; // To Calculate Standard deviation
      let featureCount = 0; // To Calculate Standard deviation
      Object.entries(feature.totalWinsDistribution).forEach(([wdKey, it]: [string, IWinDistribution]) => {
        featureTotalWin += it.win * it.count;
        featureCount += it.count;
        totalSumOverall += it.count * Math.pow((it.win - stats.metric.meanTotalWins), 2); // calculated against global meanTotalWins
        envSum += it.count * Math.pow(it.win - stats.metric.meanTotalWins, 2); // calculated against global meanTotalWins
        featureSum += it.count * Math.pow((it.win - feature.metric.meanTotalWins), 2); 
      });
      feature.metric.meanTotalWins = featureTotalWin / featureCount;
      feature.metric.rtp = featureTotalWin / tBets; 
      env.metric.rtp += feature.metric.rtp;
      setMetrics(feature.metric, featureSum, featureCount);
    });
    setMetrics(env.metric, envSum, count);
  });
  
  const globalRtp = calculateRtp(stats);
  setMetrics(stats.metric, totalSumOverall, totalGlobalCount);
  calculateRtpUpperBound(stats.metric.rtpUpperBounds, globalRtp, stats.metric.standardDeviation, totalGlobalCount);
  calculateRtpLowerBound(stats.metric.rtpLowerBounds, globalRtp, stats.metric.standardDeviation, totalGlobalCount);
  
  console.log('WRAP RTP');
  envs.filter(([name, env]: [string, IEnv]) => !!env).forEach(([name, env]: [string, IEnv]) => {
    const e = stats.envs[name];
    const totalWins = totalWinsOfEnvs[name]; // Object.keys(this.envs).map((name) => this.envs[name].totalWinInCredits).reduce((a, b) => Number(a) + Number(b), 0);
    console.log(`\t ${name} totalWins ${totalWins.toFixed(2)} rtp: ${formatRtp(e.metric.rtp)} Total bets: ${tBets} `);
  });
  
  //actual hold
  stats.actualHolds.push({ wins: totalGlobalWins, bets: tBets, spins: totalGlobalCount });
};
export const calculateRtp = (stats: IStats) => { return Object.keys(stats.envs).map(it => stats.envs[it].metric.rtp).reduce((a, b) => Number(a) + Number(b), 0); }
  
const setMetrics = (metric: IMetrics, sum: number, totalCount: number) => {
  metric.standardDeviation = calculateStandardDeviation(sum, totalCount);
  calculateVolatilityIndex(metric.volatilityIndex, metric.standardDeviation);
}
const calculateStandardDeviation = (sum: number, count: number) => {
  return Math.sqrt(( sum / ( count - 1)) );
}
const calculateVolatilityIndex = (whereTaAdd: {[p: string]: number} , standardDeviation: number) => {
  whereTaAdd['68'] = 1 * standardDeviation;
  whereTaAdd['90'] = 1.64 * standardDeviation;
  whereTaAdd['95'] = 1.96 * standardDeviation;
  whereTaAdd['99'] = 2.58 * standardDeviation;
}
const calculateRtpUpperBound = (whereToAdd: {[p: string]: number}, rtp: number, standardDeviation: number, count: number) => {
  whereToAdd['68'] = rtp + ((1 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['90'] = rtp + ((1.64 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['95'] = rtp + ((1.96 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['99'] = rtp + ((2.58 * standardDeviation) / Math.sqrt(count) );
}
const calculateRtpLowerBound = (whereToAdd: {[p: string]: number}, rtp: number, standardDeviation: number, count: number) => {
  whereToAdd['68'] = rtp - ((1 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['90'] = rtp - ((1.64 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['95'] = rtp - ((1.96 * standardDeviation) / Math.sqrt(count) );
  whereToAdd['99'] = rtp - ((2.58 * standardDeviation) / Math.sqrt(count) );
}
export const createEmptyMetrics = (): IMetrics => ({
  rtp: 0,
  meanTotalWins: 0,
  maxWin: 0,
  standardDeviation: 0,
  volatilityIndex: {},
  rtpLowerBounds: {},
  rtpUpperBounds: {}
});

export const getEnvCounts = (env: IEnv, selectedTotalBet: string = 'all') => {
  let count = 0;
  Object.entries(env.playsPerBaseTotalBet).forEach(([bet, c]: [string, number]) => {
    if (selectedTotalBet !== 'all' && `${bet}` !== `${selectedTotalBet}`) { return; }
    count += c;
  })
  return count;
}
