import * as React from 'react';
import { ScatterChart } from '../chart/scatter';
import { useModel } from '../model-loader/model-loader';
import { mapToArray } from '../../helpers/helpers';
declare var google: any;
interface IWinDistribution {
  count: number;
  win: number;
  rtp: number;
  totalBet: number;
  baseTotalBet: number;
}

export const winsOptions = [
  'luxuriousWin', 'incredibleWin', 'bigWin', 'win3', 'win2', 'win1', 'nowin',
] as const;
export type TWinLimits = typeof winsOptions[number];

interface IData { 
  name: string;
  winTypes: {
    [winType: string]: (string | number)[][]
  };
}

export const AllWins = () => {
  const [render, setRender] = React.useState(false);
  const [envData, setEnvData] = React.useState<IData | undefined>();
  const [envSelected, setEnvSelected] = React.useState('');
  const [featureData, setFeatureData] = React.useState<IData | undefined>();
  const [featureSelected, setFeatureSelected] = React.useState('');
  const { model } = useModel();

  const getLimit = (totalBet: number, totalW: number): TWinLimits => {
    if (totalW === 0) { return 'nowin'; }
    if (totalW >= (model!.config.limits.incredibleWin * totalBet) ) { return 'luxuriousWin'; }
    if (totalW >= (model!.config.limits.bigWin * totalBet) ) { return 'incredibleWin'; }
    if (totalW >= (model!.config.limits.win3 * totalBet) ) { return 'bigWin'; }
    if (totalW >= (model!.config.limits.win2 * totalBet) ) { return 'win3'; }
    if (totalW >= (model!.config.limits.win1 * totalBet) ) { return 'win2'; }
    // if (totalW >= (model.config.limits.win0 * totalBet) ) { return 'win1'; } 
    return 'win1';
  }
  React.useEffect(() => {
    if (!model) { return; }
    setFeatureData(undefined);
    setFeatureSelected('');
    if (envSelected === '') { setEnvData(undefined); return; }
    const o: any = { name: envSelected, winTypes: {} };
    const d = model.envs[envSelected];
    
    // // const baseTotalBet = getBaseTotalBet();
    // mapToArray(d.totalWinsDistribution).forEach((it: IWinDistribution) => {
    //   const wt = getLimit(it.totalBet, it.win);
    //   let data = o.winTypes[wt];
    //   if (!data) { data = [['Total Win', 'Count']]; o.winTypes[wt] = data; }
    //   // data.push([it.win / it.baseTotalBet, it.count]);
    //   data.push([it.win, it.count]);
    // });
    setEnvData(o);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envSelected]);

  React.useEffect(() => {
    if (!model) { return; }
    if (featureSelected === '') { setFeatureData(undefined); return; }
    const o: any = { name: featureSelected, winTypes: {} };
    const d = model.envs[envSelected].features[featureSelected];
    // const baseTotalBet = getBaseTotalBet();
    Object.keys(d.totalWinsDistribution).forEach((tKey) => {
      const it: IWinDistribution = d.totalWinsDistribution[tKey];
      const wt = getLimit(it.baseTotalBet, it.win);// getLimit(it.totalBet, it.win);
      let data = o.winTypes[wt];
      if (!data) { data = [['Total Win', 'Count']]; o.winTypes[wt] = data; }
      // data.push([ (it.win / it.baseTotalBet), it.count ]);
      data.push([ it.win, it.count ]);
    }); 
    setFeatureData(o);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureSelected]); 
  React.useEffect(() => {
    if (!model) { throw new Error('Model not loaded'); }
    google.charts.load('current', {packages: ['corechart', 'table', 'gauge', 'controls']});
    google.charts.setOnLoadCallback(async () => {
      setEnvSelected(Object.keys(model.envs)[0]);
      setRender(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []); 
  return !render || !model ? <></> : <>
    <div className={`win-distr-toolbar`}> 
      <div className={`win-distr-toolbarContainer`}>
        {Object.keys(model.envs).map(env => <div key={`wd-toolbar-${env}`} className={`win-distr-toolbarItem ${envSelected === env ? 'win-distr-toolbarItemSelected' : ''}`} onClick={e => setEnvSelected(env)}>
          {env}
        </div>)}
      </div>
      
      {envSelected !== '' && <div className={`win-distr-toolbarContainer`}>
        {['', ...Object.keys(model.envs[envSelected].features)].map(feature => <div key={`wd-toolbar-feature-${feature}`} className={`win-distr-toolbarItemFeature ${featureSelected === feature ? 'win-distr-toolbarItemSelected' : ''}`} onClick={e => setFeatureSelected(feature)}>
          {feature === '' ? 'All Environment' : feature}
        </div>)}
      </div>}
      {/* <div className={`sep`}></div> */}
    </div>
    <div style={{marginTop: 32}}>
      Graphs are calculated based on the proportion between win/totalbet.
    </div>
    <div>      
      {envData && featureSelected === '' && Object.keys(envData.winTypes)
        .map(winType => ({ winType, data: envData.winTypes[winType], index: winsOptions.findIndex(k => k === winType) }))
        .filter(wt => wt.winType !== 'nowin')
        .sort((a, b) => a.index < b.index ? 1 : -1)
        .map(wt => <div key={`wt-${envSelected}-${wt.winType}`}>
          <ScatterChart title={`${wt.winType} | x: win  y: count`} data={wt.data} chartHeight={512} hScaleType={'log'} vScaleType={'log'} />
        </div>)}

      { featureData && featureSelected !== '' && Object.keys(featureData.winTypes)
        .map(winType => ({ winType, data: featureData.winTypes[winType], index: winsOptions.findIndex(k => k === winType) }))
        .filter(wt => wt.winType !== 'nowin')
        .sort((a, b) => a.index < b.index ? 1 : -1)
        .map(wt => <div key={`wt-ft-${featureSelected}-${wt.winType}`}>
          <ScatterChart title={`${wt.winType} | x: win  y: count`} data={wt.data} chartHeight={512} hScaleType={'log'} vScaleType={'log'} />
        </div>)}
        
    </div>
  </>
};
