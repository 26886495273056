import * as React from 'react';
import { Chart } from '../chart/chart';
import { PieChart } from '../chart/piechart';
import {format, formatPerc, mapToArray} from '../../helpers/helpers';
import { IStats, IEnv, IWinLevel, IWinDistribution } from '../../interface';
import { useModel } from './../model-loader/model-loader';
import { getEnvRtpPieChartData } from './../../helpers/model';
import { Paytable } from './paytable/paytable';

import './model-info.css';
import { getEnvCounts } from '../live-game-loader/helpers';
declare var google: any;

// modifies the win level
export const prepareWinsDistributionInPlace = (d: IWinDistribution, levels: IWinLevel[], count: number) => {
  if (d.totalBet === 0) { return; }
  const threshold = d.win / d.totalBet;
  const wl = levels.find(it => it.threshold > threshold);
  if (!wl) { throw new Error('winlevel not found'); }
  wl.rtp = wl.rtp ?? 0;
  wl.count = wl.count ?? 0;
  wl.count += d.count;
  wl.rtp += Number(d.rtp); // ((d.count * d.win) / totalBets) * 100;
  wl.hitRate = (count / wl.count);
  return wl;
}

export const ModelInfo = (props: any) => {
  const [render, setrender] = React.useState(false); //: boolean;
  const [rtpJourney, setrtpJourney] = React.useState<any[]>([]); //: any;
  const [standardDeviationJourney, setstandardDeviationJourney] = React.useState<any>(); //: any;
  const [totalBetsUsed, setTotalBetsUsed] = React.useState<string[]>([]);

  const { model } = useModel();

  const parseModel = () => {
    if (!model) {
      setrtpJourney([]);
      setstandardDeviationJourney([]);
      console.error('No Model'); 
      return; 
    }
    const rtpJourney = [
      ['Spin', 'RTP', 'Upper 68', 'Upper 90', 'Upper 95', 'Upper 99', 'Lower 68', 'Lower 90', 'Lower 95', 'Lower 99'],
      ...(model.rtpJourney.map(it => it.map(k => Number(k)))),
    ];
    const standardDeviationJourney = [
      ['Spin', 'Mean', '68', '90', '95', '99'],
      ...(model.standardDeviationJourney.map(it => it.map(k => Number(k)))),
    ];
    
    const totalBetsUsed: string[] = [];
    mapToArray(model.envs).forEach((env: IEnv) => Object.keys(env.totalBetsUsed).filter(it => Number(it) > 0).forEach(it => totalBetsUsed.some(z => z === it) === false && totalBetsUsed.push(it)))
    setTotalBetsUsed(totalBetsUsed);

    setrender(true);
    setrtpJourney(rtpJourney);
    setstandardDeviationJourney(standardDeviationJourney);
  }
  const getRtpPieChartData = () => {
    const a: any = [ ['env', 'rtp'] ];
    let allRtp = 0;
    Object.keys(model!.envs).forEach(k => { allRtp += Number(model!.envs[k].metric.rtp); });
    const remainder = (1 - allRtp)*100;
    a.push(['---', remainder])
    Object.keys(model!.envs).map(k => a.push([k, Number(model!.envs[k].metric.rtp)*100]) );
    return a;
  }
  const getGlobalRtp = () => {
    return formatPerc( (mapToArray(model!.envs).map((k: IEnv) => k.metric.rtp).reduce((a, b) => Number(a) + Number(b), 0) ), 0);
  }

  React.useEffect(() => {
    google.charts.load('current', {packages: ['corechart', 'table', 'gauge', 'controls']});
    google.charts.setOnLoadCallback(() => { parseModel(); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !render || !model || !rtpJourney || !standardDeviationJourney ? <></> : 
  <>
    <GameInfoSummary model={model} />
    
    <div className={`sep`}></div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <h1>RTP {getGlobalRtp()}</h1> 
          <div>Bets used: {totalBetsUsed.join(',')}</div>
          <div>{format(getEnvCounts(model.envs[model.envNames.base], 'all'), 0)} Base Spins</div>
          {/* <div>Mean Win: {format(Number(model.metric.meanTotalWins))}</div> */}
        </div>
        <div style={{width: 420 }}>
          <PieChart chartType={'PieChart'} title={`RTP`} chartHeight={280} data={getRtpPieChartData()} hScaleType={'log'} />
        </div>
      </div>
    </div>

    <div className={`sep`}></div>

    {rtpJourney.length > 0 && <>
      <Chart title={'Rtp Journey - Volatility Upper and Lower bounds'} chartType='LineChart' data={rtpJourney} chartHeight={250} />
    
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 5}}>
        <div style={{fontSize: 12, marginRight: 8}}>Rtp Upper Bound: <span style={{fontWeight: 800}}>{format(Number(model.metric.rtpUpperBounds[99]) )}</span></div>
        <div style={{fontSize: 12, marginRight: 8}}>Rtp Lower Bound: <span style={{fontWeight: 800}}>{format(Number(model.metric.rtpLowerBounds[99]) )}</span></div>
        <div style={{fontSize: 12, marginRight: 8}}>Standard Deviation: <span style={{fontWeight: 800}}>{format(Number(model.metric.standardDeviation))}</span></div>  
        <div style={{fontSize: 12, marginRight: 8}}>Volatility Index (68): <span style={{fontWeight: 800}}>{format(Number(model.metric.volatilityIndex[68]))}</span></div>
        <div style={{fontSize: 12, marginRight: 8}}>Volatility Index (90): <span style={{fontWeight: 800}}>{format(Number(model.metric.volatilityIndex[90]))}</span></div>
        <div style={{fontSize: 12, marginRight: 8}}>Volatility Index (95): <span style={{fontWeight: 800}}>{format(Number(model.metric.volatilityIndex[95]))}</span></div>
        <div style={{fontSize: 12, marginRight: 8}}>Volatility Index (99): <span style={{fontWeight: 800}}>{format(Number(model.metric.volatilityIndex[99]))}</span></div>
      </div>
    </>}

    {standardDeviationJourney.length > 0 && <>
      <div className={`sep`}></div>
      <Chart title={'Volatility Index'} data={standardDeviationJourney} chartHeight={200} />
    </>}

    <div className={`sep`}></div>
    <ActualHold />

    <div className={`sep`}></div>
    <Paytable />

    <SummaryEnvsRtp model={model} />
  </>;
}

const SummaryEnvsRtp = (props: {model: IStats}) => {
  return <>
    <div className={`sep`}></div>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <h1 style={{textAlign: 'center'}}>Features RTP Summary</h1>
    <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
      {mapToArray(props.model.envs).map((env, i) => <div style={{marginRight: 32}} key={`summary-envs-${i}`}>
        <PieChart chartType={'PieChart'} title={`${env.name} Rtp`} chartHeight={360} data={getEnvRtpPieChartData(env)} hScaleType={'log'} />
      </div>)}
    </div>
  </div>
</>}


const GameInfoSummary = (props: {model: IStats}) => {
  const [info, setInfo] = React.useState<any>();
  const [feature, setFeature] = React.useState<any>();
  React.useEffect(() => {
    setInfo(props.model.config.info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !info ? null : <>
  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <h2>{info.description}</h2>
    <div className='mi-info-container'>
      {info.features.map((it: any, i: number) => <div key={`summ-${i}`} className={`mi-info-feature pointer ${it.name === feature?.name ? 'mi-info-feature-selected' : ''}`} onClick={() => setFeature(it)}>
        <h4>{it.name}</h4>
        <div className={'small pointer'}><i className={"fa-solid fa-caret-down"}></i>&nbsp; Read more...</div>
      </div>)}
    </div>
  </div>
  {!feature ? <></> : <GameInfoSummaryFeature feature={feature} onCollapse={() => setFeature(undefined)} /> }
</>}


const GameInfoSummaryFeature = (props: {feature: any, onCollapse: () => unknown}) => {
  return <div style={{display: 'flex', justifyContent: 'center'}}>
    <div style={{maxWidth: 580, padding: 16}}>
      <h3>{props.feature.name}</h3>
      <div>{props.feature.description}</div>
      {props.feature.subFeatures?.length > 0 && <table><tbody>
        { props.feature.subFeatures.map((sf: any, j: number) => <tr key={`summ-${sf.name}-${j}`}>
          <td valign='top'><h5>{sf.name}:</h5></td>
          <td>{sf.description}</td>
        </tr> ) }
        </tbody></table>}
      <div className={'small pointer'} style={{marginTop: 12}} onClick={props.onCollapse}>
        <i className={"fa-solid fa-caret-up"}></i>&nbsp; Collapse
      </div> 
    </div>
</div>
}

const ActualHold = () => {
  const [render, setrender] = React.useState(false);
  const [data, setData] = React.useState<(string | number)[][]>([]);
  let { model } = useModel();

  React.useEffect(() => {
    if (!model || !model.actualHolds) { return; }
    const d: (number | string)[][] = [['Spins', 'Actual hold']];
    model.actualHolds.forEach((it) => { d.push([it.spins, it.bets - it.wins]); });
    setData(d);
    setrender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);
  return !render || data.length === 0 ? null : <>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <h1 style={{textAlign: 'center'}}>Theoretical Hold</h1>
    <div style={{textAlign: 'center', fontSize: 14}}>Spins include base, Free, and other env spins.</div>
    <div style={{textAlign: 'center', fontSize: 14}}>x: spins, y: bets-wins.</div>
    <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
      <Chart data={data} chartHeight={200} />
    </div>
  </div>
</>}