import { IEnv } from "./../interface";

export const getEnvRtpPieChartData = (env: IEnv): (string | number)[][] => {
  const a: (string | number)[][] = [ ['env', 'rtp'] ];
  let allRtp = 0;
  Object.keys(env.features).forEach(k => { allRtp += Number(env.features[k].metric.rtp); });
  const remainder = (1 - allRtp) * 100;
  a.push(['---', remainder])
  Object.keys(env.features).forEach(k => { a.push([k, Number(env.features[k].metric.rtp)*100]); });
  return a;
}