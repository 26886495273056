import * as React from 'react';
import './win_analysis.css';
import { useModel } from '../model-loader/model-loader';
import { Chart } from '../chart/chart';
declare var google: any;
interface IWinDistribution {
  count: number;
  win: number;
  rtp: number;
  totalBet: number;
  baseTotalBet: number;
}

export const winsOptions = [
  'luxuriousWin', 'incredibleWin', 'bigWin', 'win3', 'win2', 'win1', 'nowin',
] as const;
export type TWinLimits = typeof winsOptions[number];

export const WinAnalysis = () => {
  const [render, setRender] = React.useState(false);
  const [envSelected, setEnvSelected] = React.useState('');
  const [featureSelected, setFeatureSelected] = React.useState('');
  const { model } = useModel();
  React.useEffect(() => {
    setFeatureSelected('');
  }, [envSelected]);
  React.useEffect(() => {
    if (!model) { throw new Error('Model not loaded'); }
    google.charts.load('current', {packages: ['corechart', 'table', 'gauge', 'controls']});
    google.charts.setOnLoadCallback(async () => {
      setEnvSelected(Object.keys(model.envs)[0]);
      setRender(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []); 
  return !render || !model ? <></> : <>
    <div className={`win-distr-toolbar`}> 
      <div className={`win-distr-toolbarContainer`}>
        {Object.keys(model.envs).map(env => <div key={`wd-toolbar-${env}`} className={`win-distr-toolbarItem ${envSelected === env ? 'win-distr-toolbarItemSelected' : ''}`} onClick={e => setEnvSelected(env)}>
          {env}
        </div>)}
      </div>
      
      {envSelected !== '' && <div className={`win-distr-toolbarContainer`}>
        {['', ...Object.keys(model.envs[envSelected].features)].map(feature => <div key={`wd-toolbar-feature-${feature}`} className={`win-distr-toolbarItemFeature ${featureSelected === feature ? 'win-distr-toolbarItemSelected' : ''}`} onClick={e => setFeatureSelected(feature)}>
          {feature === '' ? 'All Environment' : feature}
        </div>)}
      </div>}
      {/* <div className={`sep`}></div> */}
    </div>
    <div>
      {envSelected !== '' && featureSelected === '' && <>
        <EnvWinDividedMeanWin envSelected={envSelected} />
        <EnvWinDividedTotalBet envSelected={envSelected} />
      </>}
      
      { envSelected !== '' && featureSelected !== '' && <>
        <FeatureWinDividedMeanWin envSelected={envSelected} featureSelected={featureSelected} />
        <FeatureWinDividedTotalBet envSelected={envSelected} featureSelected={featureSelected} />
      </> }
    </div>
  </>
};

const EnvWinDividedMeanWin = ({ envSelected }: { envSelected: string }) => {
  const [data, setData] = React.useState<any>([]);
  const [render, setRender] = React.useState(false);
  const { model } = useModel();

  React.useEffect(() => {
    if (!model) { return; }
    setRender(false);
    const d = model.envs[envSelected];
    const mw = d.metric.meanTotalWins;
    const data: (number | string)[][] = [['Total Win', 'Count']];
    // // let m0 = 0;
    // // let m1 = 0;
    // Object.keys(d.totalWinsDistribution).forEach((tKey) => {
    //   const it: IWinDistribution = d.totalWinsDistribution[tKey];
    //   if (it.win === 0) { return;}
    //   // if (it.win < mw) { m0 += it.count; }
    //   // if (it.win > mw) { m1 += it.count; }
    //   const v = it.win / mw;
    //   // if (v < 0) { console.log(v)}
    //   data.push([ v, it.count ]);
    // }); 
    // // console.log(m0, m1, m0 / m1);
    setData(data);
    setTimeout(() => setRender(true), 8);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envSelected]);
  return !render ? null : <>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 64}}>
      <h1>Win / meanWin ratio</h1>
      <h4>0 = Win is the same as the mean win</h4>
    </div>
    <Chart chartType={'ColumnChart'} data={data} chartHeight={500} hAxisLogScale={true} vAxisLogScale={true} />
  </>
}
const EnvWinDividedTotalBet = ({ envSelected }: { envSelected: string }) => {
  const [data, setData] = React.useState<any>([]);
  const [render, setRender] = React.useState(false);
  const { model } = useModel();

  React.useEffect(() => {
    if (!model) { return; }
    setRender(false);
    const d = model.envs[envSelected];
    if (!d) { return; }
    const data: (number | string)[][] = [['Total Win', 'Count']];
    // Object.keys(d.totalWinsDistribution).forEach((tKey) => {
    //   const it: IWinDistribution = d.totalWinsDistribution[tKey];
    //   if (it.win === 0) { return;}
    //   const v = it.win / it.baseTotalBet;
    //   data.push([ v, it.count ]);
    // });
    setData(data);
    setTimeout(() => setRender(true), 8);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envSelected]);
  return !render ? null : <>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 64}}>
      <h1>Win / totalBet (in Base) ratio</h1>
    </div>
    <Chart chartType={'ColumnChart'} data={data} chartHeight={500} hAxisLogScale={true} vAxisLogScale={true} />
  </>
}

const FeatureWinDividedMeanWin = ({ envSelected, featureSelected }: { envSelected: string; featureSelected: string }) => {
  const [data, setData] = React.useState<any>([]);
  const [render, setRender] = React.useState(false);
  const { model } = useModel();

  React.useEffect(() => {
    if (!model) { return; }
    setRender(false);
    const d = model.envs[envSelected]?.features?.[featureSelected];
    if (!d) { return; }
    const mw = d.metric.meanTotalWins;
    const data: (number | string)[][] = [['Total Win', 'Count']];
    Object.keys(d.totalWinsDistribution).forEach((tKey) => {
      const it: IWinDistribution = d.totalWinsDistribution[tKey];
      if (it.win === 0) { return;}
      data.push([ it.win / mw, it.count ]);
    }); 
    setData(data);
    setTimeout(() => setRender(true), 8);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envSelected, featureSelected]);
  return !render ? null : 
  <>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h1>Win / meanWin ratio</h1>
    </div>
    <Chart chartType={'ColumnChart'} data={data} chartHeight={500} hAxisLogScale={true} vAxisLogScale={true} />
  </>
}
const FeatureWinDividedTotalBet = ({ envSelected, featureSelected }: { envSelected: string; featureSelected: string }) => {
  const [data, setData] = React.useState<any>([]);
  const [render, setRender] = React.useState(false);
  const { model } = useModel();

  React.useEffect(() => {
    if (!model) { return; }
    setRender(false);
    const d = model.envs[envSelected]?.features?.[featureSelected];
    if (!d) { return; }
    const data: (number | string)[][] = [['Total Win', 'Count']];
    Object.keys(d.totalWinsDistribution).forEach((tKey) => {
      const it: IWinDistribution = d.totalWinsDistribution[tKey];
      if (it.win === 0) { return;}
      data.push([ it.win / it.baseTotalBet, it.count ]);
    }); 
    setData(data);
    setTimeout(() => setRender(true), 8);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envSelected, featureSelected]);
  return !render ? null : 
  <>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h1>Win / totalBet ratio</h1>
    </div>
    <Chart chartType={'ColumnChart'} data={data} chartHeight={500} hAxisLogScale={true} vAxisLogScale={true} />
  </>
}
