import { IEnv, IFeature, IStats, IWinDistribution, IWinTypeData } from "../../../interface";
import { createEmptyMetrics, wrapRtp } from "../../live-game-loader/helpers";

export const parseModelBasedOnTotalBet = (model: IStats, totalBet: number) => {
  // console.log(`filtering by bet ${totalBet}`)
  const m = {
    prefix: model.prefix,
    envNames: model.envNames,
    envs: filterEnvsBasedOnTotalBet(model, totalBet),
    metric: model.metric,
    reelFrequencies: {},
    rtpJourney: [],
    standardDeviationJourney: [],
    actualHolds: [],
    config: model.config,
    writeConfig: model.writeConfig,
  }
  wrapRtp(m); 
  console.log(`filtered by bet: ${totalBet}`, m)
  return m;
}
export const filterEnvsBasedOnTotalBet = (model: IStats, totalBet: number) => {
  const envs: { [env: string]: IEnv} = {};
  Object.entries(model.envs).forEach(([envName, env]: [string, IEnv]) => {
    const {features, totalWinInCredits} = extractFeatures(env, totalBet);
    const triggers = 0;
    const playsPerBaseTotalBet = env.playsPerBaseTotalBet;
    const symbolWinTypes = extractSymbolWinTypes(env, totalBet);
    const count = playsPerBaseTotalBet[totalBet];
    const totalBetsUsed: {[bet: number]: number} = env.totalBetsUsed[totalBet] ? { [totalBet]: env.totalBetsUsed[totalBet] } : { 0: count };
    const totalBetInCredits = env.totalBetsUsed[totalBet] ? totalBet * count : 0;
    const data = env.data;
    const customData = env.customData;

    const e: IEnv = {
      name: envName,
      triggers,
      totalBetInCredits,
      totalWinInCredits,
      totalBetsUsed,
      playsPerBaseTotalBet,
      data,
      customData,
      features,
      symbolWinTypes,
      metric: createEmptyMetrics(),
      wins: { [totalWinInCredits]: 1 },
    }
    console.log(`filtered ${envName}`, e, count, totalWinInCredits)
    envs[envName] = e;
  });
  return envs;
}

const extractFeatures = (env: IEnv, totalBet: number) => {
  const features: { [key: string]: IFeature } = {}; 
  let totalWinInCredits = 0;
  Object.entries(env.features).forEach(([featureName, feature]: [ string, IFeature]) => {
    const x = extractTotalWinsDistribution(feature, totalBet);
    totalWinInCredits += x.totalWinInCredits;
    const data = {}; // feature.data;
    const customData = {}; // feature.customData;
    features[featureName] = {
      name: featureName,
      metric: createEmptyMetrics(),
      data,
      customData,
      totalWinsDistribution: x.totalWinsDistribution,
    }
  });
  return {features, totalWinInCredits};
}
const extractTotalWinsDistribution = (feature: IFeature, totalBet: number) => {
  const totalWinsDistribution: { [k: string]: IWinDistribution } = {};
  let totalWinInCredits = 0;
  Object.entries(feature.totalWinsDistribution).forEach(([name, twd]: [ string, IWinDistribution]) => {
    if (twd.baseTotalBet !== totalBet) { return; }
    const win = twd.win * twd.count;
    totalWinsDistribution[name] = { ...twd };
    totalWinInCredits += win;
  });
  return { totalWinsDistribution, totalWinInCredits };
}
const extractSymbolWinTypes = (env: IEnv, totalBet: number) => {
  const d: { [k: string]: IWinTypeData } = {};
  Object.entries(env.symbolWinTypes).forEach(([name, sw]: [ string, IWinTypeData]) => {
    if (sw.baseTotalBet !== totalBet) { return; }
    d[name] = {...sw};
  });
  return d;
}
