import React from 'react';
import './login.css';
import * as request from 'superagent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './../require-auth/require-auth';

function Login() {
  const [render, setRender] = React.useState(false);
  const [calling, setCalling] = React.useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let from = location.state?.from?.pathname || "/";

  const [password, setPassword] = React.useState('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = { password };
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/viz/login`).withCredentials().send(payload))?.body;
    if (!res.success) { return alert('Incorrect password'); }
    auth.signin(() => { navigate(from, { replace: true }); });
  }
  React.useEffect(() => {
    if(calling) { return; }
    setCalling(true);
    const init = async () => {
      try {
        await request.post(`${process.env.REACT_APP_API_URL}/n/slots/viz/get`).withCredentials().send();
        console.log(auth);
        auth.signin(() => { navigate(from, { replace: true }); });
      } catch (e) {
        console.error(e);
      } finally {
        setRender(true);
      }
    };
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !render ? <></> : <div className="login-container">
    <form className="login" onSubmit={(e) => onSubmit(e)}>
      <h1>Hello, please login</h1>
      <input type={'password'} value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
      <button className='btn-login' type='submit'>Login</button>
    </form>
  </div>;
}

export default Login;
