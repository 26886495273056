import * as React from 'react';

import numeral from 'numeral';

export const usd = (v: number, decimals = 2) => `${numeral(v).format(`$0,0.${range(decimals).map(i => '0').join('')}`)} USD`;

export const isAlphaNumeric = (x: any) => {
  return /^[a-z0-9]+$/i.test(x);
};
export const isAlpha = (x: any) => {
  return /^[a-z]+$/i.test(x);
};
export const isInteger = (n: any) => Number(n) === parseInt(n, undefined);
export function isNumeric(str: any) {
  if (str == null) {
    return false;
  }
  const regx = /^[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
  return regx.test(str);
}
export function heatMapColorforValue(value: number){
  var h = (1.0 - value) * 240
  return "hsl(" + h + ", 100%, 50%)";
}
export const normalize = (val: number, max: number, min: number) => (val - min) / (max - min); // 0 to 1
export function randRange(min: number, max: number) { return Math.floor(Math.random() * (max - min + 1)) + min; }

// Returns an array like: [0,1,2,3,4,5] when range(6)
export function range(a: number, b: number | null = null, step: number | null = null): number[] {
  if (!b && !step) {
    b = a;
    a = 0;
  }

  if (b! < a) {
    return range(b! + 1, a + 1, step).reverse();
  }

  step = step || 1;

  let x; const r = [];

  for (x = a; (b! - x) * step > 0; x += step) {
    r.push(x);
  }
  return r;

}
export const randomString = (n = 5) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  range(n).forEach(() => text += possible.charAt(Math.floor(Math.random() * possible.length)));
  return text;
};
  
export const hold = (time = 1000) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), time);
  });
};

export function mapToArray<T>(o: { [key: string]: T } ): T[] { return Object.keys(o).map(k => o[k]); }

export const format = (num: number, decimals = 2, suffix?: string) => {
  if (num < 0.00001) { num = 0; }
  if (!isNumeric(num)) { return <span>N/A</span>; }
  const r = numeral(num).format(`0,0.${range(decimals).map(() => '0').join('')}`);
  if (typeof r === 'object') { console.log('NUMMM', r, num); }
  return `${r}${suffix || ''}`;
}
export const formatPerc = (num: number, decimals = 2) => format(num * 100, decimals, '%');
