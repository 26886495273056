import * as React from 'react'; 
declare var google: any;
const options = {
  hAxis: {
    titleTextStyle: {color: '#607d8b'}, 
    gridlines: { count: -1, color: '#3a3a3a', opacity: 0.4}, 
    minorGridlines: { count: -1, color: '#252525', opacity: 0.4}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
  },
  vAxis: {
    titleTextStyle: {color: '#607d8b'}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
    // minValue: minPrice, 
    // maxValue: maxPrice, 
    gridlines: {color: '#0e415a', count: 2}, 
    minorGridlines: {color: '#09222e'}, 
    baselineColor: 'transparent',
  },
  legend: {
    position: 'top', alignment: 'center', textStyle: {color: '#607d8b', fontName: 'Roboto', fontSize: '10'},
  },
  colors: ['#3f51b5', '#03a9f4', '#009688', '#4caf50', '#8bc34a', '#cddc39'],
  areaOpacity: 0.24,
  lineWidth: 1,
  backgroundColor: 'transparent',
  chartArea: {
    backgroundColor: 'transparent',
    width: '90%',
    height: '90%',
  },
  // height: 200, // example height, to make the demo charts equal size
  width: '100%',
  pieSliceBorderColor: '#263238',
  pieSliceTextStyle:  {color: '#dbdbdb' }, //,'#607d8b' },
  pieHole: 0.4,
  bar: {groupWidth: '40' },
  colorAxis: {colors: ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4'] },
  datalessRegionColor: '#37474f',
  displayMode: 'regions',
  annotations: {
    textStyle: {
    fontName: 'Open Sans',
    fontSize: 12,
    bold: true,
    italic: true, 
    opacity: 1,
    }
  }
  // trendlines: {
  //     0: {
  //         type: 'exponential',
  //         color: 'green',
  //         lineWidth: 30,
  //         opacity: 0.2,
  //         visibleInLegend: false,
  //     },
  //   },
};
interface IProps {
  title?: string;
  data: any;
  chartType: 'PieChart' | 'BarChart' | 'ColumnChart';
  chartHeight?: number;
  hScaleType?: 'log';
  vScaleType?: 'log';
}
export class PieChart extends React.Component<IProps, any> {
  public chartRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IProps) {
    super(props);
    this.chartRef = React.createRef(); 
    this.state = {
      draw: false,
    };
  }
  public componentDidMount() {
    this.draw();
  }
  public draw() {
    this.setState({draw: true}, async () => {
      const o = JSON.parse(JSON.stringify(options));
      o.hAxis.scaleType = this.props.hScaleType || null;
      o.vAxis.scaleType = this.props.vScaleType || null;
      const data = google.visualization.arrayToDataTable(this.props.data);

      // data.sort([{column: 1}]);
      const t = this.props.chartType || 'PieChart';
      const chart = new google.visualization[t](this.chartRef.current);
      chart.draw(data, o);
    });
  }
  public render() {
    return (
    !this.state.draw ? 
      null :
      (<div style={{textAlign: 'center', width: '100%', marginTop: '20px'}}>
        { this.props.title && <h2 style={{marginBottom: 8}}>{ this.props.title }</h2> }
        <div>
          <div id={this.state.chartId} ref={this.chartRef} style={{width: '100%', height: this.props.chartHeight || 1024}}></div>
        </div>
      </div>)
    );
  }
}
