import * as React from 'react';
import './paytable.css';
import * as helpers from './../../../helpers/helpers';
import { useModel } from '../../model-loader/model-loader';

export const Paytable = () => {
  let model = useModel();

  const [definition] = React.useState(model.model?.config);
  console.log('definition', definition)
  return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 32}}>
        <h1 style={{marginBottom: 32}}>Paytable</h1>

        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 800, justifyContent: 'center'}}>
          { Object.keys(definition.paytable).map(key =><div key={`pay-${key}`} style={{margin: '0'}}>
              <PaytableItem name={key} paytable={definition.paytable[key]}/>
            </div>)}
        </div>
      </div>
}

const PaytableItem = (props: {name: string, paytable: number[]}) => {
  const [items] = React.useState(props.paytable.filter(it => it > 0));
  return <div className={'paytable-flex-center'} style={{position: 'relative'}}>
    <h1>{props.name}</h1>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <table>
        <tbody>
          <tr>
          {/* [2000, 500, 100, 3] */}
            <td style={{textAlign: 'right', verticalAlign: 'top'}}>
              {items.length === 0 && <>N/A</>}
              {helpers.range(items.length).map((it, i) => <div key={`${Math.random()}`} className={`symbolPayDot`} style={{marginTop: '5px'}}>
                {helpers.range(5 - i).map(k => <span key={`${Math.random()}`} className="fa-solid fa-circle-dot"></span> )}
              </div>)}
            </td>
            <td>
              {items.map((it, i) => <div key={`${Math.random()}`} className={`symbolPay`}>{it}</div> )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
};
