import * as React from 'react'; 
import { Outlet, useParams, useOutletContext, useNavigate } from 'react-router-dom';
import * as request from 'superagent';
import { Toolbar } from '../toolbar/toolbar';
import './model-loader.css';
import { IStats } from './../../interface';
import bus from './../../helpers/events';

export type ContextType = { 
  model?: IStats | undefined,
  modelId?: string,
};

export const ModelLoader = () => {
  const [render, setRender] = React.useState(false);
  const [status, setStatus] = React.useState('');
  let { modelId } = useParams<"modelId">();
  const [model, setModel] = React.useState<IStats | undefined>(undefined);
  const init = async () => {
    try {
      if (!modelId) { throw new Error('') }
      setRender(false);
      setStatus('Loading model...');
      console.log('modelId', modelId);
      setModel((await request.get(`data/${modelId}.json`))?.body);
      setStatus('Model loaded');
      setRender(true);
    } catch(e) {
      setStatus('Please choose a model');
      setRender(false);
      setModel(undefined);
    } 
  }
  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);
  React.useEffect(() => {
    const changeModelCallback = (model: IStats) => { 
      setModel(model); 
      setRender(true);
    }
    bus.on('change-model', changeModelCallback);
    return () => {
      bus.off('change-model', changeModelCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
    <Toolbar />
    {!render ? <div><h1>{status}</h1></div> : <>
      <ModelHeader model={model!} modelId={modelId!} urlPrefix={''} />
    </>}
  </>
}

export function useModel() {
  return useOutletContext<ContextType>();
}

export const ModelHeader = (props: { model: IStats, modelId: string; urlPrefix: string }) => {
  const [selected, setSelected] = React.useState('0');
  const navigate = useNavigate();
  return <>
  <h1 style={{display: 'flex', justifyContent: 'center', marginTop: 8, fontSize: 48, fontWeight: 800}}>{props.modelId}</h1>

<div className={'model-loader-menu'}>
  <div onClick={e => {setSelected('0'); navigate(`${props.urlPrefix}/${props.modelId}`) }} className={`model-loader-toolbarItem ${ selected === '0' ? 'model-loader-toolbarItemSelected' : '' }`}>Model Info</div>
  <div onClick={e => {setSelected('1'); navigate(`${props.urlPrefix}/${props.modelId}/features`) }} className={`model-loader-toolbarItem ${ selected === '1' ? 'model-loader-toolbarItemSelected' : '' }`}>Features</div>
  <div onClick={e => {setSelected('2'); navigate(`${props.urlPrefix}/${props.modelId}/win-analysis`) }} className={`model-loader-toolbarItem ${ selected === '2' ? 'model-loader-toolbarItemSelected' : '' }`}>Win Analysis</div>
  <div onClick={e => {setSelected('3'); navigate(`${props.urlPrefix}/${props.modelId}/all-wins`) }} className={`model-loader-toolbarItem ${ selected === '3' ? 'model-loader-toolbarItemSelected' : '' }`}>All Wins</div>
  {/* <div onClick={e => {setSelected('3'); navigate(`/${modelId}/rtp-contributions`) }} className={`model-loader-toolbarItem ${ selected === '3' ? 'model-loader-toolbarItemSelected' : '' }`}>RTP Contributions</div> */}
</div>
{/* <div className={`model-loader-sep`}></div> */}

<Outlet context={{ model: props.model, modelId: props.modelId } satisfies ContextType}  />

<div style={{margin: '100px 0 32px 0', display: 'flex', justifyContent: 'center'}}>Copyright 2023 fezuone s.a.s</div>
  </>
}