import * as React from 'react';
declare var google: any;
const overallOptions = {
  hAxis: {
    titleTextStyle: {color: '#fff'}, 
    gridlines: { count: 3, color: '#0e415a'}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
  },
  vAxis: {
    titleTextStyle: {color: '#fff'}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
    // minValue: minPrice, 
    // maxValue: maxPrice, 
    gridlines: {color: '#0e415a', count: 2}, 
    minorGridlines: {color: '#09222e'}, 
    baselineColor: 'transparent',
  },
  titleTextStyle: { color: 'white', fontSize: '16'},
  legend: 'none',
  // legend: {
  //   position: 'top', alignment: 'center', textStyle: {color: '#ffffff', fontName: 'Roboto', fontSize: '10'},
  // },
  colors: ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39'],
  areaOpacity: 0.24,
  // lineWidth: 1,
  backgroundColor: 'transparent',
  chartArea: {
    backgroundColor: 'transparent',
    width: '90%',
    height: '90%',
  },
  // height: 200, // example height, to make the demo charts equal size
  width: '100%',
  pieSliceBorderColor: '#263238',
  pieSliceTextStyle:  {color: '#607d8b' },
  pieHole: 0.9,
  bar: {groupWidth: '40' },
  colorAxis: {colors: ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4'] },
  datalessRegionColor: '#37474f',
  displayMode: 'regions',
  // trendlines: {
  //     0: {
  //         type: 'exponential',
  //         color: 'green',
  //         lineWidth: 30,
  //         opacity: 0.2,
  //         visibleInLegend: false,
  //     },
  //   },
};
interface IProps {
  data: (string | number)[][];
  title?: string;
  chartHeight?: number;
  hScaleType?: 'log';
  vScaleType?: 'log';
}
export class ScatterChart extends React.Component<IProps, any> {
  public chartRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IProps) {
    super(props);
    this.chartRef = React.createRef(); 
    this.state = {
      draw: false,
    };
  }
  public componentDidMount() {
    this.draw();
  }
  public draw() {
    this.setState({draw: true}, async () => {
      const options = JSON.parse(JSON.stringify(overallOptions));
      options.hAxis.scaleType = this.props.hScaleType || null;
      options.vAxis.scaleType = this.props.vScaleType || null;
      options.title = this.props.title || 'Performance';
      // options.legend = 'none';
      // const data = [this.props.data[0]];
      
      const gdata = google.visualization.arrayToDataTable(this.props.data);

      // data.sort([{column: 1}]);
      const chart = new google.visualization.ScatterChart(this.chartRef.current);
      chart.draw(gdata, options);
    });
  }
  public render() {
    return (
    !this.state.draw ? 
      null :
      (<div style={{textAlign: 'center', width: '100%', marginTop: '20px'}}>
        { this.props.title && <h2 style={{marginBottom: 8}}>{ this.props.title }</h2> }
        <div>
          <div id={this.state.chartId} ref={this.chartRef} style={{width: '100%', height: this.props.chartHeight || 1024}}></div>
        </div>
      </div>)
    );
  }
}
