import * as React from 'react';
import {ModelInfo} from './model-info/model-info';
import {ModelLoader} from './model-loader/model-loader';
import {Features} from './features/features';
import { WinAnalysis } from './win_analysis/win_analysis';
import { AllWins } from './all-wins/all-wins';
import { RTPContributions } from './rtp-contributions/rtp-contributions';
import { createHashRouter, RouterProvider, Outlet } from "react-router-dom";
import { AuthProvider, RequireAuth } from './login/require-auth/require-auth';
import { LiveGameLoader } from './live-game-loader/live-game-loader'
import Login from './login/login/login';
import './app.css';
const rtpChildren = [
  {
    path: "",
    index: true,
    element: <ModelInfo />,
  },
  {
    path: "features",
    element: <Features />,
  },
  {
    path: "win-analysis/:bet?",
    element: <WinAnalysis />,
  },
  {
    path: "all-wins/:bet?",
    element: <AllWins />,
  },
  {
    path: "rtp-contributions",
    element: <RTPContributions />,
  },          
];
const router = createHashRouter([
  { path: '/login', element: <Login /> },
  {
    path: "/live-game/:modelId?/:currency?",
    element: <Outlet />,
    children: [
      {
        path: "",
        element:  <RequireAuth><LiveGameLoader /></RequireAuth>,
        children: rtpChildren,
      },
    ],
  },
  {
    path: "/:modelId?",
    element: <Outlet />,
    // loader: rootLoader,
    children: [
      {
        path: "",
        element:  <RequireAuth><ModelLoader /></RequireAuth>,
        children: rtpChildren,
      },
    ],
  },
]);
export const App = () => {
  return <>
    <AuthProvider><RouterProvider router={router} /></AuthProvider>
  </>
}
