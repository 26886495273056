import * as React from 'react'; 
import './features.css';
import { PieChart } from '../chart/piechart';
import { IEnv, IFeature, IStats, IWinDistribution, IWinTypeData } from './../../interface';
import { mapToArray, heatMapColorforValue, normalize, format, formatPerc } from './../../helpers/helpers';
import { useModel } from '../model-loader/model-loader';
import { getEnvRtpPieChartData } from './../../helpers/model';
import { Tooltip } from 'react-tooltip'
import { parseModelBasedOnTotalBet } from './helpers';
import { formatNum, wrapRtp, getEnvCounts } from '../live-game-loader/helpers';

declare var google: any;

const colors = ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39'];
type tSortedWinTypesOrderBy = 'ofAKind' | 'symbol' | 'rtpContribution' | 'hitRate';

export const Features = () => {
  const [render, setRender] = React.useState(false);
  const [m, setM] = React.useState<IStats | undefined>(undefined);
  const [selectedTotalBet, setSelectedTotalBet] = React.useState('');
  const { model } = useModel();
  const getBaseEnv = (mod: IStats) => mod?.envs[mod?.envNames.base]!;
  const getTotalBetsUsed = (mod: IStats) => Object.keys(mod.envs[mod.envNames.base].totalBetsUsed).map(it => Number(it));

  const parseModel = () => {
    if (!model) { return; }
    if (selectedTotalBet === 'all') {
      wrapRtp(model);
      setM(model);
      console.log(model);
    } else {
      const newModel = parseModelBasedOnTotalBet(model, Number(selectedTotalBet));
      setM(newModel);
    }
    setTimeout(() => { setRender(true); }, 16);
  }
  const getMedianBet = () => {
    const base = getBaseEnv(m!);
    return Object.keys(base.totalBetsUsed).map((k) => Number(k)).reduce((acc, cv) => acc + cv, 0) / (Object.keys(base.totalBetsUsed).length || 1)
  }
  React.useEffect(() => {
    if (selectedTotalBet === '') { return; }
    parseModel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTotalBet]);
  React.useEffect(() => {
    google.charts.load('current', {packages: ['corechart', 'table', 'gauge', 'controls']});
    google.charts.setOnLoadCallback(() => { setSelectedTotalBet('all') });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !render || !m ? <></> : <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '12px 0' }}>
      <div style={{marginRight: 12}}>Filter by Total Bet</div>
      <div>
        <select value={selectedTotalBet} onChange={(e) => setSelectedTotalBet(`${e.currentTarget.value}`)}>
          <option label={'All'} value={'all'} />
          { getTotalBetsUsed(model!).map(n => <option key={`tbo${n}`} label={`${n}`} value={`${n}`} />)}
        </select>
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
      {mapToArray(m.envs).map((env: IEnv, iEnv) => 
        <div className={`env`} key={`${env.name}-${iEnv}`}>
          <PieChart chartType={'PieChart'} title={`${env.name}`} chartHeight={200} data={getEnvRtpPieChartData(env)} hScaleType={'log'} />
          <div className={`features-rtp`}>
            <div className={`features-envRtpInfo`} style={{fontWeight: 800}}>RTP: {formatPerc(env.metric.rtp)}</div>
            
            <div className={`sep`}></div>
            <div className={`features-envRtpInfo`}>Plays: {format(getEnvCounts(env, selectedTotalBet), 0)}</div>
            {env !== getBaseEnv(m) && <div className={`features-envRtpInfo`}>Hit-Rate (base): {formatHitRate(getEnvCounts(getBaseEnv(m), selectedTotalBet) / getEnvCounts(env, selectedTotalBet))}</div>}
            {env.triggers > 0 && <div className={`features-envRtpInfo`}>Triggers {format(env.triggers, 0)}</div> }
            {Object.keys(env.totalBetsUsed).length > 0 && Object.keys(env.totalBetsUsed)[0] !== '0' && <div>
              <div className={`features-envRtpInfo`}>Median bet: {getMedianBet()}</div>
              <div className={`features-envRtpInfo`} style={{fontSize: '0.6em'}}>[{Object.keys(env.totalBetsUsed).join(', ')}]</div>
            </div>}
            <div className={`features-envRtpInfo`}>Mean win {format(env.metric.meanTotalWins, 0)}</div>
            <div className={`features-envRtpInfo`}>Max win {format(env.metric.maxWin, 0)}</div>
            <div className={`features-envRtpInfo win-vs-bet-feature`}>Win vs bet {format(env.metric.meanTotalWins / getMedianBet(), 2)}</div>
            <Tooltip anchorSelect=".win-vs-bet-feature" place="top">Diff: meanWin / medianBet</Tooltip>
            <div className={`features-envRtpInfo sd-feature`}>SD {format(env.metric.standardDeviation, 2)}</div>
            <Tooltip anchorSelect=".sd-feature" place="top">Standard Deviation</Tooltip>
            <div className={`features-envRtpInfo cv-feature`}>CV {format(env.metric.standardDeviation / env.metric.meanTotalWins, 2)}</div>
            <Tooltip anchorSelect=".cv-feature" place="top">CV: Variance coefficient (sd / meanWin)</Tooltip>

            <GetCoinsMeanAverage env={env} selectedTotalBet={selectedTotalBet} />

            <div className={`sep`}></div>
            <h3>Info:</h3>
            {Object.keys(env.data).sort().map((dataKey, di) => <ul key={`env-data-${dataKey}-${di}`} className='features-env-info'>
              <li>
                <div style={{fontWeight: 400}}>{dataKey}: </div>
                <div className='info-description'>
                  {/* {format(env.data[dataKey], 0)} |  */}
                  <div>hit-rate: {formatHitRate(getEnvCounts(env, selectedTotalBet) / env.data[dataKey])}</div>
                  {env !== m.envs[m.envNames.base] && <div>hit-rate(base): {formatHitRate(getEnvCounts(m.envs[m.envNames.base], selectedTotalBet) / env.data[dataKey])}</div> }
                </div>
              </li>
            </ul>)} 
            <div className={`sep`}></div>
          </div>

          <h1 style={{marginBottom: 16}}>Features:</h1>
          { mapToArray(env.features)
            .sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))
            // .sort((a, b) => Number(b.metric.rtp) - Number(a.metric.rtp))
            .map((feature, n) => 
            <div key={`ft-${env.name}-${feature.name}-${iEnv}-${n}`} className='feature-feature'>
              {feature.metric.rtp > 0 && <>
                <h1 style={{fontWeight: 800, textTransform: 'capitalize'}}>{feature.name}</h1>
                <div style={{fontWeight: 600}}>Rtp: {formatPerc(Number(feature.metric.rtp) )}</div>
                <FeatureInfo env={env} feature={feature} model={m} selectedTotalBet={selectedTotalBet} />
              </>}

              <ReelStacks env={env} feature={feature} model={m} selectedTotalBet={selectedTotalBet} />
              <WildMultipliers env={env} feature={feature} model={m} selectedTotalBet={selectedTotalBet} />
              <Heatmap heatmap={feature.customData?.heatmap?.windowHeatmap} />

              <div style={{borderBottom: '1px solid #474747', margin: '18px 0'}}></div>
            </div>) }
            
            <SymbolOAKContributions env={env} iEnv={iEnv} model={m} selectedTotalBet={selectedTotalBet} />
        </div>)
      }
    </div>
  </>
};

const FeatureInfo = (props: { env: IEnv, feature: IFeature, model: IStats, selectedTotalBet: string}) => {
  const { env, feature } = props;
  const [model] = React.useState(props.model);
  const [selectedTotalBet] = React.useState(props.selectedTotalBet);
  const getBaseEnv = () => model?.envs[model?.envNames.base]!;
  const totalWinsDistribution = mapToArray(feature.totalWinsDistribution || {});
  const reduceByCount = (d: IWinDistribution[]) => d?.map(it => it.count).reduce((a, b) => Number(a) + Number(b), 0);
  
  const getWinsGT0 = () => reduceByCount(totalWinsDistribution.filter(it => it.win > 0));
  const getG0andLTBet = () => reduceByCount(totalWinsDistribution.filter(it => it.win > 0 && it.win < it.totalBet));
  const getEQBet = () => reduceByCount(totalWinsDistribution.filter(it => it.win === it.totalBet));
  const getGTBet = () => reduceByCount(totalWinsDistribution.filter(it => it.win > it.totalBet));
  return <div className='info-description'>
    <div>Mean Win: {formatHitRate(Number(feature.metric.meanTotalWins))}</div>
    <div>Max Win: {format(Number(feature.metric.maxWin))}</div>
    <div>Hit Rate (in env): {formatHitRate(getEnvCounts(env, selectedTotalBet) / getWinsGT0()) }</div>
    {env !== getBaseEnv() && <div>Hit Rate (against base): {formatHitRate(getEnvCounts(getBaseEnv(), selectedTotalBet) / getWinsGT0())}</div>}
    {getG0andLTBet() > 0 && <div>Win Hit Rate {'<'} totalbet : {formatHitRate(getEnvCounts(env, selectedTotalBet) / getG0andLTBet()) }</div>}
    {getEQBet() > 0 && <div>Win Hit Rate {'==='} totalBet: {formatHitRate(getEnvCounts(env, selectedTotalBet) / getEQBet()) }</div>}
    {getGTBet() > 0 && <div>Win Hit Rate {'>'} totalBet: {formatHitRate(getEnvCounts(env, selectedTotalBet) / getGTBet()) }</div>}
    {feature.metric.standardDeviation > 0 && <div>Standard Deviation: {format(Number(feature.metric.standardDeviation))}</div>}
  </div>
}

const Heatmap = (props: { heatmap?: number[][] }) => {
  const [heatmap] = React.useState(props.heatmap);
  if (!heatmap) { return null;}
  let max = 0; let min = Number.MAX_SAFE_INTEGER;
  heatmap.forEach(reel => reel.forEach(row => {
    if (row > max) { max = row; }
    if (row < min) { min = row; }
  }));
  const normalized = heatmap.map(reel => reel.map(row =>  normalize(row, max, 0)));
  return <div style={{width: '100%'}}>
    {/* <h3 style={{fontWeight: 800}}>Heatmap</h3> */}
    <div style={{display: 'flex', margin:'8px 0'}}>
      { normalized.map((reel, i) => <div key={`reel-${i}-${Math.random()}`} style={{minWidth: 50}}>
        { reel.map((row, j) => <div key={`reel-${i}-row-${j}-${Math.random()}`} style={{ backgroundColor: `${heatMapColorforValue(row)}`, fontSize: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 12, color: 'rgba(0,0,0,1)'}}>
          {Math.round(heatmap[i][j])}
        </div>) }
      </div>) }
    </div>
  </div>
}

const WildMultipliers = (props: { env: IEnv, feature: IFeature, model: IStats, selectedTotalBet: string}) => {
  const [totalBetsInCredits, setTotalBetsInCredits] = React.useState(0); //: number;
  const [selectedTotalBet] = React.useState(props.selectedTotalBet); //: number;
  const getBaseEnv = () => props.model?.envs[props.model?.envNames.base]!;

  const [wildMultipliers] = React.useState(props.feature.customData?.wildMultipliers);

  const parseModel = () => {
    if (!props.model) { return; }
    let totalBetsInCredits = 0;
    mapToArray(props.model.envs).forEach((env: IEnv) => {
      Object.keys(env.totalBetsUsed).forEach(bet => totalBetsInCredits += (Number(bet) * env.totalBetsUsed[bet]));
    });
    setTotalBetsInCredits(totalBetsInCredits);
  }
  React.useEffect(() => {
    parseModel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.model]);

  return !wildMultipliers ? null : <div style={{margin: '8px 0 16px 0'}} className='info-description'>
    <h4 style={{color: 'white', fontSize: '1.2em' }}>Wild Multipliers (wins):</h4>
    {Object.keys(wildMultipliers)
    .sort()
    .map(key => ({...wildMultipliers[key], key}))
    .map((wm, i) => <div key={`wildMultiplierData${props.env.name}-${props.feature.name}-${i}`}>
      <span>
        <span style={{fontWeight: 800, color: 'white'}}>{wm.key}: </span>
        <span style={{}}>
          <span style={{fontWeight: 400, marginRight: 8}}>rtp: {formatPerc(wm.totalWin / totalBetsInCredits)}</span>
          <div>hit-rate (env): {formatHitRate(getEnvCounts(props.env, selectedTotalBet) / wm.count)}</div>
          { props.env !== getBaseEnv() && <div>hit-rate (base): {formatHitRate(getEnvCounts(getBaseEnv(), selectedTotalBet) / wm.count)}</div> }
        </span>
      </span>
    </div>)}
  </div>
}
const ReelStacks = (props: { env: IEnv, feature: IFeature, model: IStats, selectedTotalBet: string}) => {
  const [totalBetsInCredits, setTotalBetsInCredits] = React.useState(0); //: number;
  const [selectedTotalBet] = React.useState(props.selectedTotalBet); //: number;
  const getBaseEnv = () => props.model?.envs[props.model?.envNames.base]!;

  const [reelStacks] = React.useState(props.feature.customData?.['Reel Stacks']);

  const parseModel = () => {
    if (!props.model) { return; }
    let totalBetsInCredits = 0;
    mapToArray(props.model.envs).forEach((env: IEnv) => {
      Object.keys(env.totalBetsUsed).forEach(bet => totalBetsInCredits += (Number(bet) * env.totalBetsUsed[bet]));
    });
    setTotalBetsInCredits(totalBetsInCredits);
  }
  React.useEffect(() => {
    parseModel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.model]);

  return !reelStacks ? null : <div style={{margin: '8px 0 16px 0'}} className='info-description'>
    <h4 style={{color: 'white', fontSize: '1.2em' }}>Reel Stacks:</h4>
    <div>NOTE: reel is 0 index</div>
    {Object.keys(reelStacks)
    .sort()
    .map(key => ({...reelStacks[key], key}))
    .map((wm, i) => <div key={`reelStacksData${props.env.name}-${props.feature.name}-${i}`}>
      <span>
        <span style={{fontWeight: 800, color: 'white'}}>{wm.key}: </span>
        <span style={{}}>
          <span style={{fontWeight: 400, marginRight: 8}}>rtp: {formatPerc(wm.totalWin / totalBetsInCredits)}</span>
          <div>hit-rate (env): {formatHitRate(getEnvCounts(props.env, selectedTotalBet) / wm.count)}</div>
          { props.env !== getBaseEnv() && <div>hit-rate (base): {formatHitRate(getEnvCounts(getBaseEnv(), selectedTotalBet) / wm.count)}</div> }
        </span>
      </span>
    </div>)}
  </div>
}

const SymbolRtpContribution = (props: {data: (string | number)[][]}) => {
  if (!props.data || props.data.length < 2) { return <></>; }
  return <PieChart data={props.data} chartType={'PieChart'} chartHeight={250} title={`Contribution per Symbol`} />
}

const SymbolOAKContributions = (props: { env: IEnv, iEnv: number, model: IStats, selectedTotalBet: string}) => {
  const [env] = React.useState(props.env); //: IEnv;
  const [selectedTotalBet] = React.useState(props.selectedTotalBet); //: IEnv;
  const [symbolRtp, setSymbolRTP] = React.useState<(string | number)[][]>([]);
  const [sortedWinTypes, setsortedWinTypes] = React.useState<IWinTypeData[]>([]);
  const [sortedWinTypesOrderBy, setsortedWinTypesOrderBy] = React.useState<tSortedWinTypesOrderBy>('ofAKind');
  const [sortedWinTypesOrd, setsortedWinTypesOrd] = React.useState(false); //: boolean;
  
  React.useEffect(() => {
    if (!props.model) { return; }
    const sortedWinTypes: IWinTypeData[] = [];
    let symbolRtp: (string | number)[][] = [];

    mapToArray(env.symbolWinTypes).forEach(it => {
      let a: IWinTypeData | undefined = sortedWinTypes.find(swt => swt.symbol === it.symbol && it.ofAKind === swt.ofAKind);
      if (!a) { a = { totalBet: 0, baseTotalBet: 0, symbol: it.symbol, ofAKind: it.ofAKind, count: 0, win: 0, rtpContribution: 0, envHitRate: 0 }; sortedWinTypes.push(a); }
      a.count += it.count; a.win += it.win; a.totalBet += it.totalBet; a.rtpContribution += it.rtpContribution;
    });

    sortedWinTypes
      .sort((a, b) => (a.symbol > b.symbol) ? 1 : ((b.symbol > a.symbol) ? -1 : 0))
      .sort((a, b) => b.ofAKind - a.ofAKind);
    
    // Symbol RTP contribution
    const srtp: (string | number)[][] = [[env.name, 'rtp contribution']];
    symbolRtp = srtp;
    sortedWinTypes.forEach(it => {
      let s = srtp.find(k => k[0] === it.symbol);
      if (!s) { s = [it.symbol, 0]; srtp.push(s); }
      s[1] = Number(s[1]) + (it.rtpContribution * 100);
    });
    
    setsortedWinTypes(sortedWinTypes);
    setSymbolRTP(symbolRtp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.model]);
  return sortedWinTypes.length === 0 ? null : <>
    <SymbolRtpContribution data={ symbolRtp } />
    <div className='oak-contrib' key={`swt-${env.name}`}>
    <table style={{width: '100%', marginTop: 16}}>
      <thead>
        <tr>
          <td onClick={e => { setsortedWinTypesOrderBy('ofAKind'); setsortedWinTypesOrd(!sortedWinTypesOrd) }} style={{cursor: 'pointer'}}>OAK</td>
          <td onClick={e => { setsortedWinTypesOrderBy('symbol'); setsortedWinTypesOrd(!sortedWinTypesOrd) }} style={{cursor: 'pointer'}}>Sym</td>
          <td onClick={e => { setsortedWinTypesOrderBy('rtpContribution'); setsortedWinTypesOrd(!sortedWinTypesOrd) }} style={{cursor: 'pointer'}}>Rtp-contrib</td>
          <td onClick={e => { setsortedWinTypesOrderBy('hitRate'); setsortedWinTypesOrd(!sortedWinTypesOrd) }} style={{cursor: 'pointer'}}>hit-rate</td>
        </tr>
      </thead>
      <tbody>
        { sortedWinTypes
          .sort((a: IWinTypeData, b: IWinTypeData) => {
            const f1 = sortedWinTypesOrd ? a : b; const f2 = sortedWinTypesOrd ? b : a;
            if (sortedWinTypesOrderBy === 'symbol') { return (f1.symbol > f2.symbol) ? 1 : ((f2.symbol > f1.symbol) ? -1 : 0); }
            return (f1 as any)[sortedWinTypesOrderBy] - (f2 as any)[sortedWinTypesOrderBy];
          })
          .map((sym: IWinTypeData, n: number) => 
        <tr key={`${env.name}-symbol-info-${props.iEnv}-${n}`} style={{color: colors[sym.ofAKind]}}>
          <td>{sym.ofAKind}</td>
          <td>{sym.symbol}</td>
          <td>{formatPerc(sym.rtpContribution, 5)}</td>
          <td>
            <div>{format(getEnvCounts(env, selectedTotalBet) / sym.count, 1)}</div>
            <div style={{fontSize: 8, color: 'rgba(255,255,255,0.6)',  marginTop: -4}}>Count: {format(sym.count, 0)}</div>
          </td>
        </tr>) }
      </tbody>
    </table>
  </div>
  </>
}

const GetCoinsMeanAverage = (props: {env: IEnv, selectedTotalBet: string}) => {
  interface IAverageType { average: number, count: number, hits: number}
  interface IAverage { [totalBet: number]: IAverageType }
  const averageData: IAverage = props.env.customData?.coinAverages;
  if (!averageData) {return null;}
  let average = 0; let c = 0;
  Object.entries(averageData).forEach(([totalBet, info]: [string, IAverageType]) => {
    if (props.selectedTotalBet !== 'all' && props.selectedTotalBet !== `${totalBet}`) { return; }
    average += info.average; c++;
  })
  return <div className='features-envRtpInfo'>Mean feature plays: {average / c}</div>
}

const formatHitRate = (n: number) => <span style={{color: 'rgba(255,255,255)'}}>{format(n, 1)}</span>
