import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as request from 'superagent';
import { IEnv, IFeature, IStats, IWinDistribution, IWinTypeData } from '../../interface';
import { ModelHeader } from '../model-loader/model-loader';
import { wrapRtp, createEmptyMetrics } from './helpers';

export const LiveGameLoader = () => {
  const [render, setRender] = React.useState(false);
  let params = useParams();
  const [modelId, setModelId] = React.useState(params.modelId ?? 'ff001-starstruck-lines');
  const [currency, setCurrency] = React.useState(params.currency ?? 'SW');
  const [model, setModel] = React.useState<IStats | undefined>(undefined);
  const init = async () => {
    try {
      if (modelId === '' || currency === '') { return; }
      setRender(false);
      console.log('modelId', modelId);
      const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/viz/get`).withCredentials().send({modelId, currency}))?.body;
      if (!res) { throw new Error('No model and currency found'); }
      const model = extractModel(res);
      sanityCheckForModelData(model);
      wrapRtp(model);
      console.log('model', model);
      setModel(model);
      // bus.emit('change-model', stats);
      setRender(true);
    } catch(e) {
      console.error(e);
      setRender(false);
    } 
  }
  React.useEffect(() => { init(); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [modelId, currency]);
  return !render ? null : <>
    <ModelHeader model={model!} modelId={modelId!} urlPrefix={'/live-game'} />
  </>;
}

const extractModel = (props: {data: any, config: any}) => {
  const { data, config } = props;
  const d = data.env; // convertDotNotationToObject(data)[modelId][currency].env; // use this when redis
  console.log(`Extracting model`, d)
  const stats: IStats = {
    prefix: '',
    envNames: extractEnvNames(d),
    envs: extractEnv(d),
    metric: createEmptyMetrics(),
    reelFrequencies: {}, // can be left empty
    rtpJourney: [],
    standardDeviationJourney: [],
    actualHolds: [],
    config,
    writeConfig: {},
  };
  return stats;
}
const keyFromDb = (s: string) => s.replaceAll('!!!', '.');

const extractEnvNames = (sourceEnvs: any) => {
  const envNames: { base: string;  [k: string]: string} = { base: ''};
  Object.keys(sourceEnvs).forEach(envName => { const k = keyFromDb(envName); envNames[k] = k; });
  return envNames;
}
const extractEnv = (sourceEnvs: any) => {
  const envs: { [k: string]: IEnv} = {};
  Object.keys(sourceEnvs).forEach(k => {
    const envName = keyFromDb(k);
    const sourceEnv = sourceEnvs[k];
    // console.log(`adding env`, envName);
    envs[envName] = {
      name: envName,
      triggers: sourceEnv.triggers ?? 0,
      totalBetInCredits: sourceEnv.totalBetInCredits ?? 0,
      totalWinInCredits: sourceEnv.totalWinInCredits ?? 0,
      totalBetsUsed: sourceEnv.totalBetsUsed ?? 0,
      playsPerBaseTotalBet: sourceEnv.playsPerBaseTotalBet ?? 0,
      data: sourceEnv.data ?? {},
      customData: extractCustomData(sourceEnv.customData),
      features: extractFeatures(sourceEnv),
      symbolWinTypes: extractSymbolWinTypes(sourceEnv.symbolWinTypes), 
      metric: createEmptyMetrics(),
      wins: sourceEnv.wins ?? {},
    };
  });
  return envs;
}
const extractFeatures = (source: any) => {
  const features: { [key: string]: IFeature } = {};
  Object.entries(source.features).forEach(([key, feature]: [ string, any]) => {
    const sourceFeature = feature as IFeature;
    const featureName = keyFromDb(key);
    // console.log('feature', featureName)
    features[featureName] = {
      name: featureName,
      metric: createEmptyMetrics(),
      data: sourceFeature.data ?? {},
      customData: extractCustomData(sourceFeature.customData),
      totalWinsDistribution: extractTotalWinsDistribution(sourceFeature.totalWinsDistribution ?? {}),
    }
  });
  return features;
}
const extractTotalWinsDistribution = (sd: {[k: string]: {count: number}}): { [k: string]: IWinDistribution} => {
  const d: { [k: string]: IWinDistribution } = {};
  Object.entries(sd ?? {}).forEach(v => {
    const wd: IWinDistribution = { baseTotalBet: 0, totalBet: 0, count: 0, win: 0, rtp: 0 };
    const a = keyFromDb(v[0]).split("_");
    wd.win = Number(a[0]);
    wd.totalBet = Number(a[1]);
    wd.baseTotalBet = Number(a[2]);
    wd.count = Number(v[1].count);
    wd.rtp = 0;
    d[`random@${Math.random()}`] = wd; // as made by rtp
  })
  return d;
}
const extractCustomData = (cd: any): { [k: string]: any } => {
  if (!cd) { return {}; }
  const customData: { [k: string]: any} = {}
  Object.keys(cd).forEach(k => {
    switch (k) {
      case "heatmap":
        customData[k] = extractHeatmap(cd[k]);
        break;
      // case "wildMultipliers":
      //   customData[k] = extractWildMultipliers(cd[k]);
      //   break;
      default:
        customData[k] = cd[k];
    }
  });
  return customData;
}
const extractHeatmap = (sourceHeatmap: any) => {
  const heatmap: { windowHeatmap: number[][] } = { windowHeatmap: [] }
  // cd comes in the form of { reel-0: { row-0: number } }
  Object.keys(sourceHeatmap).forEach((col) => {
    const reel = sourceHeatmap[col];
    const ci = Number(col.split('-')[1]);
    Object.keys(reel).forEach(rw => {
      const ri = Number(rw.split('-')[1]);
      if (!heatmap.windowHeatmap[ci]) { heatmap.windowHeatmap[ci] = []; }
      heatmap.windowHeatmap[ci][ri] = sourceHeatmap[col][rw];
    })
  });
  return heatmap;
}
const extractSymbolWinTypes = (swt: { [k: string]: {count: number, win: number }}): {[p: string]: IWinTypeData} => {
  if (!swt) { return {}; }
  const d: {[p: string]: IWinTypeData} = {};
  Object.keys(swt).forEach((key) => {
    const m = swt[key];
    const a = keyFromDb(key).split('_'); // symbol=f1@totalBet=50@baseTotalBet=50@ofAKind=3
    // console.log(a);
    const symbol = a[0];
    const totalBet = Number(a[1]);
    const baseTotalBet = Number(a[2]);
    const ofAKind = Number(a[3]);
    const fk = `symbol=${symbol}@totalBet=${totalBet}@ofAKind=${ofAKind}@${Math.random()}`;
    const it: IWinTypeData = {
      totalBet,
      baseTotalBet,
      symbol,
      ofAKind,
      count: m.count,
      win: m.win,
      rtpContribution: 0,
      envHitRate: 0,
    }
    d[fk] = it;
  });
  return d;
}

// const convertDotNotationToObject = (data: {[key: string]: number}) => {
//   const o: any = {};
//   Object.entries(data).forEach(v => { // ff001-starstruck-lines.gold:env:base:count
//     const key = v[0];
//     const n = v[1];
//     const a = key.split(':'); // env.Coin Spins.count
//     let prevKey = o;
//     a.forEach((k, i) => {
//       const isLast = i === a.length - 1;
//       if (isLast) { prevKey[k] = n; return; }
//       if (!prevKey[k]) { prevKey[k] = {}}
//       prevKey = prevKey[k];
//     });
//   });
//   console.log('Dot notation'); 
//   console.log(o['ff001-starstruck-lines']['gold']['env']);
//   sanityCheckForServerData(o['ff001-starstruck-lines']['gold']['env']);
//   return o;
// };

const sanityCheckForServerData = (data: { [key: string]: any}) => {
  type TCosito = {totalWinInCredits: number, totalWinDistribution: number; };
  const d: {[env: string]:TCosito} = {};

  Object.entries(data).forEach(([envName, env]) => {
    const t = { totalWinInCredits: 0, totalWinDistribution: 0};
    d[envName] = t;

    t.totalWinInCredits += env.totalWinInCredits ?? 0;
    Object.entries(env.features).forEach(([featureName, feature] : [string, any]) => {
      Object.entries(feature?.totalWinsDistribution ?? {}).forEach(([key, wd] : [string, any]) => {
        const n = Number(key.split('_')[0]) * wd.count;
        t.totalWinDistribution += n;
      });
    });
    // console.log(`🚩 checking ${envName}`);
    if (t.totalWinDistribution !== t.totalWinInCredits) {
      console.log(`🚩🚩🚩 \t ${envName} expected Win ${t.totalWinInCredits} ${t.totalWinDistribution}`)
    }
  });
}
const sanityCheckForModelData = (stats: IStats) => {
  const a: {[env: string]: { totalBets: number; totalWinInCredits: number; totalBetsBasedOnTotalBetsUsed: number; totalWinsBasedOnTotalWinsDistribution: number, totalWinsBasedOnWins: number }} = {};
  Object.entries(stats.envs).forEach(([envName, env]) => {
    const t = { totalBets: env.totalBetInCredits, totalWinInCredits: env.totalWinInCredits, totalWinsBasedOnTotalWinsDistribution: 0, totalBetsBasedOnTotalBetsUsed: 0, totalWinsBasedOnWins: 0 };
    a[envName] = t;
    Object.entries(env.totalBetsUsed).forEach(([bet, count]: [string, number]) => { 
      const n = Number(bet) * count;
      t.totalBetsBasedOnTotalBetsUsed += n;
    });
    Object.entries(env.features).forEach(([featureName, feature]) => {
      Object.entries(feature.totalWinsDistribution).forEach(([_, v]) => { t.totalWinsBasedOnTotalWinsDistribution += Number(v.win) * v.count; });
    });
    Object.entries(env.wins).forEach(([win, count]) => { t.totalWinsBasedOnWins += Number(win) * count; });
    if (t.totalWinsBasedOnTotalWinsDistribution !== t.totalWinInCredits) { console.log(`\t 🔔​🔔​🔔​ ${envName} expected Win ${t.totalWinInCredits} ${t.totalWinsBasedOnTotalWinsDistribution}`) }
  });
  console.log(`Sanity Check`, a); 
  Object.entries(a).forEach(([k, v]) => {
    console.log(`\t ${k}: byTotalBets: ${v.totalBets} byTBetsUsed ${v.totalBetsBasedOnTotalBetsUsed} TWinsDistr: ${v.totalWinsBasedOnTotalWinsDistribution} wins: ${v.totalWinsBasedOnWins}`);
  });
}
