import * as React from 'react';
import * as helpers from '../../helpers/helpers';
declare var google: any;
const options = {
  hAxis: {
    titleTextStyle: {color: '#607d8b'}, 
    gridlines: { count: 3, color: '#0e415a'}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
  },
  vAxis: {
    titleTextStyle: {color: '#607d8b'}, 
    textStyle: { color: '#b0bec5', fontName: 'Roboto', fontSize: '10', bold: true },
    // minValue: minPrice, 
    // maxValue: maxPrice, 
    gridlines: {color: '#0e415a', count: 2}, 
    minorGridlines: {color: '#09222e'}, 
    baselineColor: 'transparent',
  },
  legend: {
    position: 'top', alignment: 'center', textStyle: {color: '#607d8b', fontName: 'Roboto', fontSize: '10'},
  },
  colors: ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39'],
  areaOpacity: 0.24,
  lineWidth: 1,
  backgroundColor: 'transparent',
  chartArea: {
    backgroundColor: 'transparent',
    width: '90%',
    height: '90%',
  },
  // height: 200, // example height, to make the demo charts equal size
  width: '100%',
  pieSliceBorderColor: '#263238',
  pieSliceTextStyle:  {color: '#607d8b' },
  pieHole: 0.9,
  bar: {groupWidth: '40' },
  colorAxis: {colors: ['#3f51b5', '#2196f3', '#03a9f4', '#00bcd4'] },
  datalessRegionColor: '#37474f',
  // displayMode: 'regions',
  // trendlines: {
  //     0: {
  //         type: 'exponential',
  //         color: 'green',
  //         lineWidth: 30,
  //         opacity: 0.2,
  //         visibleInLegend: false,
  //     },
  //   },
};
interface IProps {
  chartType?: 'AreaChart' | 'ColumnChart' | 'ScatterChart' | 'LineChart';
  itemsToStart?: number;
  data: (string | number)[][];
  title?: string;
  hAxisLogScale?: boolean;
  vAxisLogScale?: boolean;
  chartHeight?: number;
}
export class Chart extends React.Component<IProps, any> {
  public dashboardRef: React.RefObject<HTMLDivElement>;
  public chartRef: React.RefObject<HTMLDivElement>;
  public filterRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IProps) {
    super(props);
    this.dashboardRef = React.createRef();
    this.chartRef = React.createRef();
    this.filterRef = React.createRef();
    this.state = {
      draw: false,
      filterId: helpers.randomString(10),
      chartId: helpers.randomString(10),
    };
  }
  public componentDidMount() {
    // events.once('data-loaded', () => this.draw() );
    this.draw();
  }
  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.data.length !== this.props.data.length) {
      this.draw();
    }
  }
  public draw() {
    this.setState({draw: false}, () => {
      this.setState({draw: true}, () => {
        const chartType = this.props.chartType ? this.props.chartType : 'AreaChart';
        const itemsToShow = this.props.itemsToStart ?? this.props.data[this.props.data.length - 1][0];
        const opts = JSON.parse(JSON.stringify(options));
        const dashboard = new google.visualization.Dashboard(this.dashboardRef.current);
        const filterOptions: any = {}; //Object.assign({}, options);
        filterOptions.filterColumnIndex = 0;
        filterOptions.ui = opts;
        filterOptions.ui.hAxis.logScale = this.props.hAxisLogScale === true;
        filterOptions.ui.vAxis.logScale = this.props.vAxisLogScale === true;
        const slider = new google.visualization.ControlWrapper({
          controlType: 'ChartRangeFilter',
          containerId: this.state.filterId,
          options: filterOptions,
          state: {range: {start: 0, end: itemsToShow }},
        });
        const chart = new google.visualization.ChartWrapper({
          chartType,
          containerId: this.state.chartId,
          options: opts,
          // options: {
          //     chartArea: {height: '90%', width: '75%'},
          //     // vAxis: {title: 'Total Wins', minValue: 0},
          //     ui: {
          //         snapToData: true,
          //     },
          // },
        });

        const gdata = google.visualization.arrayToDataTable(this.props.data);
        dashboard.bind(slider, chart);
        dashboard.draw(gdata, options);
      });
    });
  }
  public render() {
    return (
    !this.state.draw ? 
      null :
      (<div style={{textAlign: 'center', width: '100%', marginTop: '16px'}}>
        {this.props.title && <h1 style={{marginBottom: 8}}>{this.props.title}</h1>}
        <div ref={this.dashboardRef}>
          <div id={this.state.chartId} ref={this.chartRef} style={{width: '100%', height: this.props.chartHeight || 400}}></div>
          <div id={this.state.filterId} ref={this.filterRef} style={{width: '100%', height: '50px', textAlign: 'center'}}></div>
        </div>
      </div>)
    );
  }
}
