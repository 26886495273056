import * as React from 'react'; 
import './rtp-contributions.css';
import { PieChart } from '../chart/piechart';
import { IChart, IWinLevel } from './../../interface';
import { mapToArray } from './../../helpers/helpers';
import { useModel } from '../model-loader/model-loader';
import { prepareWinsDistributionInPlace } from '../model-info/model-info';
import { getEnvCounts } from '../live-game-loader/helpers';
declare var google: any;
export const winsOptions = [
  'luxuriousWin', 'incredibleWin', 'bigWin', 'win3', 'win2', 'win1', 'nowin',
] as const;
export type TWinLimits = typeof winsOptions[number];

export const RTPContributions = () => {
  const [render, setRender] = React.useState(false);
  const [rtpCharts, setrtpCharts] = React.useState<IChart[]>([]); //: IChart[];
  const [hitRateCharts, sethitRateCharts] = React.useState<IChart[]>([]); //: IChart[];

  const { model } = useModel();

  const parseModel = () => {
    if (!model) { return; }
    const rtpCharts: IChart[] = [];
    const hitRateCharts: IChart[] = [];

    mapToArray(model.envs).forEach(env => {
      const rtpContributionChart: any = [
        ['Win Level', 'Rtp contribution', { role: 'annotation' }], // , 'Hit Rate', { role: 'annotation' }],
      ];
      const hitRateChart: any = [
        ['Win Level', 'Hit Rate', { role: 'annotation' }], // , 'Hit Rate', { role: 'annotation' }],
      ];
      rtpCharts.push({
        title: `${env.name.toUpperCase()} - Features rtp contribution per win levels`,
        chart: rtpContributionChart,
        scale: undefined,
        description: 'Values sum the top bar.',
      });
      hitRateCharts.push({
        title: `${env.name.toUpperCase()} - Features hit-rates per win levels`,
        chart: hitRateChart,
        scale: 'log',
        description: 'Values are against the count of the environment (unrelated with eachother)',
      });

      mapToArray(env.features).forEach(f => {
        rtpContributionChart[0].push(`${f.name}-rtp contrib`); rtpContributionChart[0].push({ role: 'annotation' });
        hitRateChart[0].push(`${f.name}-hit rate`); hitRateChart[0].push({ role: 'annotation' });
      });

      const envWinLevels: IWinLevel[] = JSON.parse(JSON.stringify(model.config.winLevels)).map((it: any) => ({...it, env: env.name, featureName: ''})).sort((a: any, b: any) => a.threshold - b.threshold);
      // mapToArray(env.totalWinsDistribution).forEach(d => prepareWinsDistribution(d, envWinLevels, env.count) );

      model.config.winLevels.forEach((winLevel: any) => {
        const rtpC: (string|number)[] = [];
        const hitRateC: (string|number)[] = [];
        
        rtpContributionChart.push(rtpC);
        hitRateChart.push(hitRateC);

        rtpC[0] = winLevel.name;
        hitRateC[0] = winLevel.name;

        const tEnv = envWinLevels.find(tE => tE.name === winLevel.name);
        if (!tEnv) { throw new Error('Win level not found'); }
        rtpC[1] = Number(tEnv.rtp) || 0;
        rtpC[2] = `${tEnv.rtp || 0}`;
        hitRateC[1] = Number(tEnv.hitRate || 0);
        hitRateC[2] = Number(tEnv.hitRate || 0);
        
        let i = 3;
        mapToArray(env.features).forEach(f => {
          const featureWinLevels: IWinLevel[] = JSON.parse(JSON.stringify(model.config.winLevels)).map((it: any) => ({...it, env: env.name, featureName: f.name})).sort((a: any, b: any) => a.threshold - b.threshold);
          mapToArray(f.totalWinsDistribution).forEach(d => prepareWinsDistributionInPlace(d, featureWinLevels, getEnvCounts(env, 'all')));
          const fEnv = featureWinLevels.find(fe => fe.name === winLevel.name);
          if (!fEnv) { throw new Error('Win level not found'); }
          rtpC[i] = Number(fEnv.rtp);
          rtpC[i + 1] = `${f.name} - ${!fEnv.rtp ? '0' : Number(fEnv.rtp).toFixed(3)}`;
          hitRateC[i] = Number(fEnv.hitRate);
          hitRateC[i + 1] = `${f.name} - ${!fEnv.hitRate ? '0' : fEnv.hitRate.toFixed(3)}`;
          i += 2; 
        });
      });
    });
    setRender(true);
    setrtpCharts(rtpCharts);
    sethitRateCharts(hitRateCharts);
  }

  React.useEffect(() => {
    if (!model) { throw new Error('Model not loaded'); }
    google.charts.load('current', {packages: ['corechart', 'table', 'gauge', 'controls']});
    google.charts.setOnLoadCallback(async () => {
      parseModel();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []); 
  return !render || !model ? <></> : <>
    
    { rtpCharts.map( (chart, i) => <div key={`rtp-chart-${i}`}>
      <h2>{chart.title}</h2>
      <div>{ chart.description }</div>
      <PieChart chartType={'BarChart'} data={chart.chart} hScaleType={chart.scale} />
    
      <div className={'sep'}></div>
    </div>)}

    { hitRateCharts.map( (chart, i) => <div key={`hit-rate-chart-${i}`}>
      <h2>{chart.title}</h2>
      <div>{ chart.description }</div>
      <PieChart chartType={'BarChart'} data={chart.chart} hScaleType={chart.scale } />
    </div>)}
  </>
};
